import React, { useEffect } from 'react';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, DialogContentText, Grid, Theme, createStyles, makeStyles } from '@material-ui/core';
import { Form } from 'react-final-form';
import { FormApi, ValidationErrors } from 'final-form';
import { checkIfIntialValuesIsEqualToValues } from '../../helpers/commonHelper';
import DialogBox from '../../Dashboard/Component/DialogBox';
import { useQuery } from 'react-apollo';
import { RvLoader } from '../../components/Loader';
import { CustomField, CustomFieldListData, CustomFieldListParams, CustomFieldListQuery } from '../tabs/customFieldGroups/CustomFieldGroupSelectedTab';
import { getField } from '../common/getField';
import { CustomFieldGroup } from '../tabs/customFieldGroups/CustomFieldGroupsTab';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            textTransform: 'capitalize',
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        button: {
            margin: theme.spacing(1),
            minWidth: '83px',
        },
        textField: {
            width: '100%',
        },
        dropZoneFont: {
            '& .MuiDropzoneArea-text': {
                fontSize: '1em',
            }
        },
        dropZone: {
            minHeight: 'fit-content !important',
            '& .MuiDropzoneArea-root': {
                minHeight: 'fit-content !important'
            }
        },
    }),
);

interface CustomFieldPreviewDialogProps extends Pick<DialogProps, 'open' | 'onClose' | 'maxWidth' | 'fullWidth'> {
    title?: string;
    description?: string;
    data?: CustomField[];
    customFeildGroupData?: CustomFieldGroup;
}

interface CustomFieldForm {
    isFormSubmit: boolean;
    [key: string]: any;
}

export const CustomFieldPreviewDialog: React.FC<CustomFieldPreviewDialogProps> = (props) => {
    const classes = useStyles();

    const [showDiscard, setShowDiscard] = React.useState<boolean>(false);

    const [customFields, setCustomFields] = React.useState<CustomField[]>(props.data || []);

    const customFieldListQuery = useQuery<CustomFieldListData, CustomFieldListParams>(CustomFieldListQuery, {
        variables: {
            areaOfLawGuid: props.customFeildGroupData?.areaOfLawGuid || null,
            customFieldGroupGuid: props.customFeildGroupData?.guid || null,
            locationGuid: props.customFeildGroupData?.locationGuid || null
        },
        skip: props.data !== undefined
    });

    useEffect(() => {

        if (customFieldListQuery.data && customFieldListQuery.data.crm && customFieldListQuery.data.crm.customField) {
            setCustomFields(customFieldListQuery.data.crm.customField);
        }

    }, [customFieldListQuery.data, customFieldListQuery.loading, customFieldListQuery.error]);
    
    const initialValues: CustomFieldForm = {
        isFormSubmit: false,
    };

    const handleClose = (form: FormApi<CustomFieldForm>, values: CustomFieldForm) => () => {

        const areValuesEqual = checkIfIntialValuesIsEqualToValues(initialValues, values);

        if (!areValuesEqual) {
            setShowDiscard(true);
        } else {
            if (props.onClose) {
                props.onClose({}, 'backdropClick');
            }
        }
    };

    const onDiscardChanges = (agree: boolean) => {
        if (agree) {
            if (props.onClose) {
                props.onClose({}, 'backdropClick');
            }
        }
        setShowDiscard(false);
    };

    const onSubmit = (values: CustomFieldForm, form: FormApi<CustomFieldForm, CustomFieldForm>) => {
        // tslint:disable-next-line: no-console
        console.log('onSubmit', values);

        
    };

    const onValdiate = (values: CustomFieldForm) => {
        return undefined;
    };

    const onFieldRequiredError = (errors: ValidationErrors, form: FormApi<CustomFieldForm>, values: CustomFieldForm) => {
        // code here
        console.log('errors', errors);
    };

    const controlButtonComponent = (form: FormApi<CustomFieldForm>, submitting: boolean, pristine: boolean, values: CustomFieldForm) => {
        return (
            <div className={classes.buttonContainer}>
                {/* <RenderCount /> */}
                {/* <div>
                    <Button
                        color="primary"
                        className={classes.button}
                        disabled={submitting || pristine}
                        onClick={() => {
                            form.change('isFormSubmit', true);
                            form.submit();
                        }}
                    >
                        Save
                    </Button>
                </div> */}
                <div>
                    <Button color="primary" type="button" className={classes.button} onClick={handleClose(form, values)}>
                        Cancel
                    </Button>
                </div>
            </div>
        );
    };
    
    const standarFields = (form: FormApi<CustomFieldForm>, submitting: boolean, pristine: boolean, values: CustomFieldForm) => {
        
        if (customFields.length === 0) {
            return (
                <div>
                    No Custom Fields Found
                </div>
            );
        }

        return (
            <Grid container={true} spacing={1}>

                {customFields.filter((field) => field.isActive).map((field, index) => (
                    <Grid item={true} xs={12} sm={12} md={12} lg={12} key={index}>
                        {getField(field, index)}
                    </Grid>
                ))}
            </Grid>
        );
    };
    
    if (customFieldListQuery.loading) {
        return (
            <Dialog 
                {...props}
            >
                <DialogTitle className={classes.header}>{props.title}</DialogTitle>
                <DialogContent>
                    {props.description && (
                        <DialogContentText>
                            {props.description}
                        </DialogContentText>
                    )}
                    <RvLoader />
                </DialogContent>
            </Dialog>
        );
    }

    return (
        <>
            <DialogBox
                title="Custom Field Group"
                // tslint:disable-next-line:max-line-length
                content={`Are you sure you want to close the form?`}
                show={showDiscard}
                isAgree={onDiscardChanges}
                disAgreeLabel={'No'}
                agreeLabel={'Yes'}
            />
            <Form
                onSubmit={onSubmit}
                // mutators={{ ...arrayMutators }}
                initialValues={initialValues}
                validate={onValdiate}
                keepDirtyOnReinitialize={true}
                initialValuesEqual={() => true}
                // debug={(values) => {
                //     // tslint:disable-next-line: no-console
                //     console.log('Debug Values', values);
                // }}
                subscription={{ submitting: true, pristine: true, values: true, errors: true }}
                render={({ errors, handleSubmit, form, submitting, pristine, values }) => {
                    if (values.isFormSubmit) {
                        onFieldRequiredError(errors, form, values);
                    }

                    return (
                        <form 
                            onSubmit={handleSubmit}
                            id="customFieldGroupForm"
                        >
                            <Dialog 
                                {...props}
                                onClose={handleClose(form, values)}
                                maxWidth="md"
                                fullWidth={true}
                            >
                                <DialogTitle className={classes.header}>
                                    {props.title}
                                </DialogTitle>
                                <DialogContent>
                                    {props.description && (
                                        <DialogContentText>
                                            {props.description}
                                        </DialogContentText>
                                    )}
                                    {standarFields(form, submitting, pristine, values)}
                                </DialogContent>
                                <DialogActions>{controlButtonComponent(form, submitting, pristine, values)}</DialogActions>
                            </Dialog>
                        </form>
                    );
                }}
            />
        </>
    );
};