import gql from "graphql-tag";

export type CustomFieldByHierarchyQueryParams = {
    customFieldGroupGuid: string | null;
    areaOfLawGuid: string | null;
};

export type CustomFieldByHierarchyData = {
    loading?: boolean;
    networkStatus?: number;
    crm: CRM;
};

export type CRM = {
    customFieldByHierarchy: CustomFieldByHierarchy[];
};

export type CustomFieldByHierarchy = {
    customFieldGroupGuid:        string;
    customFieldGroupName:        string;
    customFieldGroupDescription: string;
    count:                       number;
    customField:                 CustomField[];
};

export type CustomField = {
    guid:                             string;
    customFieldId:                    string;
    name:                             string;
    description:                      null | string;
    instructions:                     null | string;
    customFieldType:                  number;
    customFieldLookupListGuid:        null | string;
    customFieldLookupListDescription: null | string;
    isMandatory:                      boolean;
    isActive:                         boolean;
    sequence:                         number;
    customFieldLookupItems:           CustomFieldLookupItem[] | null;
};

export type CustomFieldLookupItem = {
    guidId:      string;
    description: string;
    sequence:    number;
};

export const CustomFieldByHierarchyQuery = gql`
    query CustomFieldByHierarchy ($customFieldGroupGuid: String, $areaOfLawGuid: String) {
        crm {
            customFieldByHierarchy (customFieldGroupGuid: $customFieldGroupGuid, areaOfLawGuid: $areaOfLawGuid) {
                customFieldGroupGuid
                customFieldGroupName
                customFieldGroupDescription
                count
                customField {
                    guid
                    customFieldId
                    name
                    description
                    instructions
                    customFieldType
                    customFieldLookupListGuid
                    customFieldLookupListDescription
                    isMandatory
                    isActive
                    sequence
                    customFieldLookupItems {
                        guidId
                        description
                        sequence
                    }
                }
            }
        }
    }
`;