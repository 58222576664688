import { 
    Breadcrumbs,
    Chip,
    CircularProgress,
    createStyles,
    IconButton,
    InputAdornment,
    lighten,
    Link,
    makeStyles, 
    Paper, 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    TextField, 
    Theme, 
    Toolbar,
    Tooltip,
    Typography
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
// tslint:disable-next-line: max-line-length
import { 
    DragDropContext, 
    Draggable, 
    // DraggableProvided, 
    // DraggableStateSnapshot, 
    Droppable, 
    DropResult, 
    ResponderProvided 
} from 'react-beautiful-dnd';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import Check from '@material-ui/icons/Check';
import _ from 'lodash';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { mainTheme } from '../../../Theme';
import { client } from '../../..';
import { showNotification } from '../../../App';
import { useHistory, useLocation } from 'react-router-dom';
import { CustomFieldGroupItemDialog } from '../../dialogs/CustomFieldGroupItemDialog';
import CheckIcon from '@material-ui/icons/Check';
import { FieldTypeReverseMapping } from '../../selectors/CustomFieldTypeSelector';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { CustomFieldPreviewDialog } from '../../dialogs/CustomFieldPreviewDialog';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
            position: 'relative'
        },
        loadingWrapper: {
            position: 'absolute',
            zIndex: 1,
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255,255,255, 0.6)',
        },
        icon: {
            color: mainTheme.BrandColors.TemplatePrimary,
        },
        tableCell: {
            paddingTop: 0,
            paddingBottom: 0,
            '&:hover': {
                '& .drag-icon': {
                    color: mainTheme.BrandColors.TemplatePrimary
                }
            }
        },
        textField: {
            minWidth: 200,
            maxWidth: '100%',
        },
        dragIcon: {
            marginLeft: '-10px',
            marginRight: 10,
            color: '#BDBDBD'
        },
        tableContentWrapper: {
            display: 'flex',
            alignItems: 'center',
        },
        description: {
            cursor: 'pointer'
        }
    })
);

const useToolbarStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
            display: 'flex',
            justifyContent: 'space-between'
        },
        highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
        title: {
            flex: '1 1 100%',
        },
        icon: {
            color: mainTheme.BrandColors.TemplatePrimary,
        },
        rightToolbarWrapper: {
            display: 'flex',
            alignItems: 'center',
        },
        tableTitle: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
        },
        tableHeading: {
            color: mainTheme.BrandColors.TemplatePrimary
        },
        chip: {
            marginLeft: '8px'
        },
        breadcrumbsWrapper: {
            display: 'flex',
            alignItems: 'center'
        },
        breadcrumbs: {

        },
        breadcrumbsTitle: {
            color: mainTheme.BrandColors.TemplatePrimary
        },
    })
);

interface EnhancedTableToolbarProps {
    title: string;
    subTitle: string;
    count?: React.ReactNode;
    onClickAdd?: () => void;
    onSearch?: (search: string) => void;
    onPreview?: () => void;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {

    const classes = useToolbarStyles();

    const history = useHistory();

    const [search, setSearch] = useState('');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        
        setSearch(event.target.value);

        if (props.onSearch) {
            props.onSearch(event.target.value);
        }
    };

    const clearSearch = () => {
        setSearch('');
        if (props.onSearch) {
            props.onSearch('');
        }
    };

    const handleBreadCrumbClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        history.goBack();
    };

    return (
        <Toolbar
            className={classes.root}
        >
            <div className={classes.breadcrumbsWrapper}>
                <Breadcrumbs separator="›" aria-label="breadcrumb" className={classes.breadcrumbs}>
                    <Link color="inherit" href="/" onClick={handleBreadCrumbClick}>
                        Custom Field Groups
                    </Link>
                    <Typography color="textPrimary" className={classes.breadcrumbsTitle}>{props.subTitle}</Typography>
                </Breadcrumbs>
                <Chip className={classes.chip} label={props.count} />
            </div>
            <div className={classes.rightToolbarWrapper}>
                <Tooltip title="Preview">
                    <IconButton 
                        aria-label="preview"
                        onClick={props.onPreview}
                    >
                        <VisibilityIcon className={classes.icon}/>
                    </IconButton>
                </Tooltip>
                <TextField
                    // className={classes.margin}
                    placeholder="Search"
                    value={search}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon fontSize="small" className={classes.icon} />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="start">
                                <IconButton
                                    onClick={clearSearch}
                                    size="small"
                                >
                                    <CloseIcon fontSize="small" className={classes.icon} />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    onChange={handleChange}
                />
                <Tooltip title="Add">
                    <IconButton 
                        aria-label="filter list"
                        onClick={props.onClickAdd}
                    >
                        <AddBoxRoundedIcon className={classes.icon}/>
                    </IconButton>
                </Tooltip>
            </div>
        </Toolbar>
    );
};

interface CustomFieldGroupSelectedTabItem extends CustomField {
    isEdit: boolean;
    isDelete: boolean;
}

interface CustomFieldGroupSelectedTabState {
    items: CustomFieldGroupSelectedTabItem[];
    isAdd: boolean;
    search: string;
    isDelete: boolean;
    isEdit: boolean;
    isFormOpen?: boolean;
    selectedData: CustomField | null;
    isPreviewOpen?: boolean;
}

export default function CustomFieldGroupSelectedTab() {

    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    
    const customFieldGroupGuid = searchParams.get('tabKey');
    const areaOfLawGuid = searchParams.get('areaOfLawGuid');
    const locationGuid = searchParams.get('locationGuid');
    const groupId = searchParams.get('groupId');
    const title = searchParams.get('title');
    const titleDescription = searchParams.get('description');

    const classes = useStyles();

    const { loading, error, data, refetch } = useQuery<CustomFieldListData, CustomFieldListParams>(CustomFieldListQuery, {
        variables: {
            areaOfLawGuid: areaOfLawGuid,
            customFieldGroupGuid: customFieldGroupGuid,
            locationGuid: locationGuid
        },
        skip: !customFieldGroupGuid
    });

    const [state, setState] = useState<CustomFieldGroupSelectedTabState>({
        items: [],
        isAdd: false,
        search: '',
        isEdit: false,
        isDelete: false,
        selectedData: null
    });

    const textFieldPlaceHolder = 'Description';

    useEffect(() => {
        if (!error && data && data.crm && data.crm.customField ) {
            setState((prevState) => {
                return {
                    ...prevState,
                    items: data.crm.customField.map((value) => {
                        return { 
                            guid: value.guid,
                            customFieldGroupGuid: value.customFieldGroupGuid,
                            customFieldGroupName: value.customFieldGroupName,
                            customFieldId: value.customFieldId,
                            name: value.name,
                            description: value.description,
                            instructions: value.instructions,
                            customFieldLookupListGuid: value.customFieldLookupListGuid,
                            customFieldLookupListDescription: value.customFieldLookupListDescription,
                            isMandatory: value.isMandatory,
                            isActive: value.isActive,
                            sequence: value.sequence,
                            customFieldType: value.customFieldType,
                            customFieldLookupItems: value.customFieldLookupItems,
                            isDelete: false,
                            isEdit: false
                        };
                    }).sort((a, b) => a.sequence < b.sequence ? -1 : a.sequence > b.sequence ? 1 : 0)
                };
            });
        }
    // tslint:disable-next-line: align
    }, [data, error, loading]);

    useEffect(() => {
        
        const subscribeFirmObj = subscribeFirm();

        return () => {
            subscribeFirmObj.unsubscribe();
        };
    // tslint:disable-next-line: align
    }, []);

    const subscribeFirm = () => {
        return (
            // let subscription =
            client
                .subscribe<EnquiryCustomFieldNotificationSubscriptionData>({
                    fetchPolicy: 'no-cache',
                    query: EnquiryCustomFieldNotificationSubscription,
                    variables: {},
                })
                .subscribe({
                    // tslint:disable-next-line:no-any
                    next(results) {
                        if (results.data?.enquiryCustomFieldNotification.status) {

                            const { enquiryCustomFieldNotification } = results.data;

                            showNotification('Success', enquiryCustomFieldNotification.message, 'info');

                            refetch();

                        } else {
                            showNotification('Failed', results.data?.enquiryCustomFieldNotification.message || '', 'error');
                        }
                    },
                    // tslint:disable-next-line:no-any
                    error(err: any) {
                        // tslint:disable-next-line:no-console
                        console.error(err);
                        showNotification(null, err, 'error');
                    },
                })
        );
    };
    
    // itemInputRef.current = getItems(10).map((ref, index) => itemInputRef.current[index] = React.createRef());

    const reorder = (list: CustomFieldGroupSelectedTabItem[], startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
      
        return result;
    };

    const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
    
        const items = reorder(
            state.items,
            result.source.index,
            result.destination.index
        ).map((item: CustomFieldGroupSelectedTabItem, index: number) => {
            return {
                ...item,
                sequence: index
            };
        });

        setState((prevState) => {
            
            client
            .mutate<UpdateEnquiryCustomFieldSequenceMutationData, UpdateEnquiryCustomFieldSequenceMutationParams>({
                mutation: UpdateEnquiryCustomFieldSequenceMutation,
                variables: {
                    customFieldGroupGuid: customFieldGroupGuid || '',
                    input: items.map((item: CustomFieldGroupSelectedTabItem) => {
                        return {
                            guidId: item.guid, 
                            sequence: item.sequence,
                        };
                    })
                },
            })
            // tslint:disable-next-line: no-any
            .then((results: { data: any }) => {
                if (results.data) {
                    if (results.data.error !== undefined) {
                        showNotification( 'Failed to update Task Type Group sorting', results.data.error, 'error');
                    }
                }
            })
            // tslint:disable-next-line:no-any
            .catch((reason: any) => {
                showNotification('Failed to update Task Type Group sorting', reason, 'error');
            });

            return {
                ...prevState,
                items
            };
        });

    };

    // const getListStyle = (isDraggingOver: boolean) => ({
    //     background: isDraggingOver ? 'lightblue' : 'lightgrey',
    //     padding: grid,
    //     width: 250
    // });

    // tslint:disable-next-line: no-any
    const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
        // styles we need to apply on draggables
        ...draggableStyle,

        ...(isDragging && {
            background: 'rgb(235,235,235)'
        }),
        position: 'static',
        zIndex: 1
    });

    const onClickAdd = () => {
        setState((prevState) => {
            return {
                ...prevState,
                // isAdd: true
                isFormOpen: true,
                selectedData: null
            };
        });
    };

    const onClickCancel = () => {
        setState((prevState) => {
            return {
                ...prevState,
                isAdd: false,
                isEdit: false,
                isDelete: false,
            };
        });
    };

    const onSearch = (search: string) => {
        setState((prevState) => {
            return {
                ...prevState,
                search
            };
        });
    };

    const onPreview = () => {
        setState((prevState) => {
            return {
                ...prevState,
                isPreviewOpen: true
            };
        });
    };

    const onEditRowEnable = (item: CustomFieldGroupSelectedTabItem) => () => {
        // const items = [...state.items];
        
        // const index = items.indexOf(item);

        // items[index].isEdit = true;

        // setState((prevState) => {
        //     return {
        //         ...prevState,
        //         items,
        //         isEdit: true,
        //     };
        // });

        setState((prevState) => {
            return {
                ...prevState,
                isFormOpen: true,
                selectedData: item
            };
        });

    };

    const onCancelEditRow = (item: CustomFieldGroupSelectedTabItem) => () => {
        // code here..
        const items = [...state.items];
        
        const index = items.indexOf(item);

        items[index].isEdit = false;

        setState((prevState) => {
            return {
                ...prevState,
                items,
                isEdit: false,
            };
        });
    };

    const onEditRow = (item: CustomFieldGroupSelectedTabItem, index: number) => () => {
        // code here
    };

    const onDeleteRowEnable = (item: CustomFieldGroupSelectedTabItem) => () => {

        setState((prevState) => {

            const items = [...prevState.items];
        
            const index = items.indexOf(item);

            items[index].isDelete = true;

            return {
                ...prevState,
                items,
                isDelete: true
            };
        });
    };

    const onCancelDeleteRow = (item: CustomFieldGroupSelectedTabItem) => () => {
        // code here..

        setState((prevState) => {

            const items = [...prevState.items];
        
            const index = items.indexOf(item);

            items[index].isDelete = false;

            return {
                ...prevState,
                items,
                isDelete: false
            };
        });
    };

    const onDeleteRow = (item: CustomFieldGroupSelectedTabItem) => () => {
        
        setState((prevState) => {

            client
            .mutate<DeleteEnquiryCustomFieldMutationData, DeleteEnquiryCustomFieldMutationParams>({
                mutation: DeleteEnquiryCustomFieldMutation,
                variables: {
                    customFieldGroupGuid: customFieldGroupGuid || '',
                    guidId: item.guid
                },
            })
            // tslint:disable-next-line: no-any
            .then((results: { data: any }) => {
                if (results.data) {
                    if (results.data.error !== undefined) {
                        showNotification( 'Failed to Delete Custom Field', results.data.error, 'error');
                    }
                }
            })
            // tslint:disable-next-line:no-any
            .catch((reason: any) => {
                showNotification('Failed to Delete  Custom Field', reason, 'error');
            });

            const tItems = [...prevState.items];
            tItems.splice(tItems.indexOf(item), 1);

            return {
                ...prevState,
                isDelete: false,
                items: tItems
            };
        });
    };

    const onAddItem = () => {
        // code here
    };

    const onChangeUpdate = (item: CustomFieldGroupSelectedTabItem, index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        // textField = event.target.value;
    };

    const onChangeAdd = (event: React.ChangeEvent<HTMLInputElement>) => {
        // textField = event.target.value;
    };

    // tslint:disable-next-line: no-any
    const DroppableComponent = (onDragEndComponent: (result: DropResult, provided: ResponderProvided) => void) => (props: any) =>  {
        return (
            <DragDropContext onDragEnd={onDragEndComponent}>
                <Droppable droppableId={'1'} direction="vertical">
                    {(provided) => {
                        return (
                            <TableBody ref={provided.innerRef} {...provided.droppableProps} {...props}>
                                {props.children}
                                {provided.placeholder}
                            </TableBody>
                        );
                    }}
                </Droppable>
            </DragDropContext>
        );
    };

    // tslint:disable-next-line: no-any
    const DraggableComponent = (item: CustomFieldGroupSelectedTabItem, index: number) => (props: any) => {
        return (
            <Draggable draggableId={item.guid} index={index}>
                {(provided, snapshot) => (
                    <TableRow
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
    
                        {...props}
                    >
                        {!!(!item.isDelete && !item.isEdit)  && (
                             <>
                                <TableCell className={classes.tableCell}>
                                    <div className={classes.tableContentWrapper}>
                                        <DragIndicatorIcon fontSize="small" className={`${classes.dragIcon} drag-icon`} />
                                        <div
                                            className={classes.description}
                                        >
                                            {item.customFieldId}
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    {item.name}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    {FieldTypeReverseMapping[item.customFieldType]}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    {item.description}
                                </TableCell>
                                {/* <TableCell className={classes.tableCell}>
                                    {item.instructions}
                                </TableCell> */}
                                <TableCell className={classes.tableCell}>
                                    {item.isMandatory && (
                                        <CheckIcon />
                                    )}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    {item.isActive && (
                                        <CheckIcon />
                                    )}
                                </TableCell>
                                {/* <TableCell className={classes.tableCell} align="center">
                                    <IconButton
                                        onClick={onClickTagCounts(item)}
                                    >
                                        <Badge color="primary" badgeContent={item.customFieldLookupListItemCount || undefined}>
                                            <LocalOfferOutlinedIcon color="primary" />
                                        </Badge>
                                    </IconButton>
                                </TableCell> */}
                                <TableCell className={classes.tableCell} align="right">
                                    <IconButton 
                                        aria-label="Edit"
                                        disabled={state.isAdd || state.isEdit || state.isDelete}
                                        color="primary"
                                        onClick={onEditRowEnable(item)}
                                    >
                                        <Edit />
                                    </IconButton>
                                    <IconButton 
                                        aria-label="Delete"
                                        disabled={state.isAdd || state.isEdit || state.isDelete}
                                        color="primary"
                                        onClick={onDeleteRowEnable(item)}
                                    >
                                        <DeleteOutline />
                                    </IconButton>
                                </TableCell>
                            </>
                        )}

                        {!!(item.isDelete && state.isDelete) && (
                            <>
                                <TableCell 
                                    className={classes.tableCell} 
                                    colSpan={6}
                                >
                                    Are you sure you want to delete this row?
                                </TableCell>
                                <TableCell className={classes.tableCell} align="right">
                                    <IconButton 
                                        aria-label="ok"
                                        // disabled={state.isAdd}
                                        color="primary"
                                        onClick={onDeleteRow(item)}
                                    >
                                        <Check />
                                    </IconButton>
                                    <IconButton 
                                        aria-label="cancel"
                                        // disabled={state.isAdd}
                                        color="primary"
                                        onClick={onCancelDeleteRow(item)}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </TableCell>
                            </>
                        )}
                        {!!(item.isEdit && state.isEdit) && (
                            <>
                                <TableCell 
                                    className={classes.tableCell} 
                                    colSpan={6}
                                >
                                    <TextField 
                                        placeholder={textFieldPlaceHolder}
                                        defaultValue={item.description}
                                        onChange={onChangeUpdate(item, index)}
                                        className={classes.textField}
                                    />
                                </TableCell>
                                <TableCell className={classes.tableCell} align="right">
                                    <IconButton 
                                        aria-label="ok"
                                        disabled={state.isAdd}
                                        color="primary"
                                        onClick={onEditRow(item, index)}
                                    >
                                        <Check />
                                    </IconButton>
                                    <IconButton 
                                        aria-label="cancel"
                                        disabled={state.isAdd}
                                        color="primary"
                                        onClick={onCancelEditRow(item)}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </TableCell>
                            </>
                        )}
                        
                    </TableRow>
                )}
            </Draggable>
        );
    };

    const getDraggableRows = () => {
        let filteredItems = state.items;
        
        if (state.search) {
            filteredItems = state.items.filter((item: CustomFieldGroupSelectedTabItem) => {
                return item.description.toLowerCase().indexOf(state.search) >= 0;
            });
        }       

        return (
            _.map(filteredItems, (item, index) => {
                return (
                    <TableRow 
                        component={DraggableComponent(item, index)} 
                        key={item.guid}
                    />
                );
            })
        );
    };

    return (
        <>
            {state.isFormOpen && !loading && data && (
                <CustomFieldGroupItemDialog 
                    open={state.isFormOpen || false}
                    enquiryCustomFieldGroupGuid={customFieldGroupGuid || ''}
                    groupId={groupId || ''}
                    count={data.crm.customField.length || 0}
                    data={state.selectedData}
                    customFieldId={state.items.map((item) => item.customFieldId)}
                    onClose={() => {
                        setState((prevState) => {
                            return {
                                ...prevState,
                                isFormOpen: false,
                            };
                        });
                    }}
                />
            )}
            
            {state.isPreviewOpen && (
                <CustomFieldPreviewDialog 
                    open={state.isPreviewOpen || false}
                    data={data?.crm.customField || []}
                    title={title || ''}
                    description={titleDescription || ''}
                    onClose={() => {
                        setState((prevState) => {
                            return {
                                ...prevState,
                                isPreviewOpen: false,
                            };
                        });
                    }}
                />
            )}

            <div className={`${classes.root}`}>
                <Paper className={classes.paper}>
                    {!!loading && (
                        <div className={classes.loadingWrapper}>
                            <CircularProgress />
                        </div>
                    )}
                    <EnhancedTableToolbar 
                        title="Custom Fields"
                        subTitle={title || ''}
                        count={state.items.length}
                        onClickAdd={onClickAdd}
                        onSearch={onSearch}
                        onPreview={onPreview}
                    />
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Custom Field Type</TableCell>
                                    <TableCell>Description</TableCell>
                                    {/* <TableCell>Instructions</TableCell> */}
                                    <TableCell>Mandatory</TableCell>
                                    <TableCell>Active</TableCell>
                                    <TableCell align="right">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody component={DroppableComponent(onDragEnd)}>
                                {!!state.isAdd && (
                                    <TableRow>
                                        <TableCell 
                                            className={classes.tableCell} 
                                            colSpan={6}
                                        >
                                            <TextField
                                                placeholder={textFieldPlaceHolder}
                                                className={classes.textField}
                                                onChange={onChangeAdd}
                                            />
                                        </TableCell>
                                        <TableCell className={classes.tableCell} align="right">
                                            <Tooltip title="Save">
                                                <IconButton 
                                                    aria-label="Save"
                                                    onClick={onAddItem}
                                                >
                                                    <Check className={classes.icon}/>
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Cancel">
                                                <IconButton 
                                                    aria-label="Delete"
                                                    onClick={onClickCancel}
                                                >
                                                    <CloseIcon className={classes.icon}/>
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                )}
                                {
                                    getDraggableRows()
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </div>
        </>
    );
}

export type CustomFieldListParams = {
    customFieldGroupGuid: string | null;
    areaOfLawGuid: string | null;
    locationGuid: string | null;
};

export type CustomFieldListData = {
    loading?: boolean;
    networkStatus?: number;
    crm: CRM;
};

export type CRM = {
    customField: CustomField[];
};

export type CustomField = {
    guid:                      string;
    customFieldGroupGuid:      string;
    customFieldGroupName:      string;
    customFieldId:             string;
    name:                      string;
    description:               string;
    instructions:              string;
    customFieldLookupListGuid: string;
    customFieldLookupListDescription: string;
    isMandatory:               boolean;
    isActive:                  boolean;
    sequence:                  number;
    customFieldType:           number;
    customFieldLookupItems:    CustomFieldLookupItem[];
};

export type CustomFieldLookupItem = {
    description: string;
    guidId: string;
    sequence: number;
};


export const CustomFieldListQuery = gql`
    query CustomFieldList($customFieldGroupGuid: String, $areaOfLawGuid: String, $locationGuid: String) {
        crm {
            customField(customFieldGroupGuid: $customFieldGroupGuid, areaOfLawGuid: $areaOfLawGuid, locationGuid: $locationGuid) {
                guid
                customFieldGroupGuid
                customFieldGroupName
                customFieldId
                name
                description
                instructions
                customFieldLookupListGuid
                customFieldLookupListDescription
                isMandatory
                isActive
                sequence
                customFieldType
                customFieldLookupItems {
                    description
                    guidId
                    sequence
                }
            }
        }
    }
`;

type EnquiryCustomFieldNotificationSubscriptionData = {
    enquiryCustomFieldNotification: EnquiryCustomFieldNotification;
};


type EnquiryCustomFieldNotification = {
    customFieldGroupGuid: null | string;
    description:          null | string;
    guidId:               string;
    id:                   number;
    status:               boolean;
    message:              string;
}

const EnquiryCustomFieldNotificationSubscription = gql`
    subscription enquiryCustomFieldNotification {
        enquiryCustomFieldNotification {
            id
            customFieldGroupGuid
            description
            guidId
            status
            message
        }
    }
`;

type DeleteEnquiryCustomFieldMutationParams = {
    customFieldGroupGuid: string;
    guidId: string;
};

type DeleteEnquiryCustomFieldMutationData = {
    deleteEnquiryCustomField: DeleteEnquiryCustomField;
};

type DeleteEnquiryCustomField = {
    error:  null;
    status: boolean;
};

const DeleteEnquiryCustomFieldMutation = gql`
    mutation DeleteEnquiryCustomField($customFieldGroupGuid: String, $guidId: String){
        deleteEnquiryCustomField(customFieldGroupGuid: $customFieldGroupGuid, guidId: $guidId) {
            error
            status
        }
    }
`;

type EnquiryCustomFieldInput = {
    guidId: string;
    sequence: number;
};

type UpdateEnquiryCustomFieldSequenceMutationParams = {
    input: EnquiryCustomFieldInput[];
    customFieldGroupGuid: string;
};

type UpdateEnquiryCustomFieldSequenceMutationData = {
    updateEnquiryCustomFieldSequence: UpdateEnquiryCustomFieldSequence;
};

type UpdateEnquiryCustomFieldSequence = {
    error:  null;
    status: boolean;
};

const UpdateEnquiryCustomFieldSequenceMutation = gql`
    mutation UpdateEnquiryCustomFieldSequence($input: [LookupSequence], $customFieldGroupGuid: String){
        updateEnquiryCustomFieldSequence(input: $input, customFieldGroupGuid: $customFieldGroupGuid){
            error
            status
        }
    }
`;