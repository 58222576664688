/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Autocomplete, RenderInputParams } from '@material-ui/lab';
import { TextField, CircularProgress } from '@material-ui/core';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import gql from 'graphql-tag';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { useQuery } from 'react-apollo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        width: '100%',
    },
  })
);

export type CustomFieldLookUpListItem = IAutoCompleteItem;

interface CustomFieldLookupListSelectorProps<T> {
    disabled?: boolean;
    required?: boolean;
    multiple?: boolean;
    // tslint:disable-next-line: no-any
    value?: CustomFieldLookUpListItem | any; // value only available in single select
    error?: boolean;
    onSelection?: (value: CustomFieldLookUpListItem | CustomFieldLookUpListItem[], name: string) => void;
    className?: string;
    helperText?: string;
    name?: string;
    label?: string;
    disablePortal?: boolean;
    disableCloseOnSelect?: boolean;
    onBlur?: React.FocusEventHandler<HTMLDivElement>;
}

export const CustomFieldLookupListSelector = <T, >(props: CustomFieldLookupListSelectorProps<T>) => {

    const classes = useStyles();

    const customFieldLookupListQuery = useQuery<CustomFieldLookupListData>(CustomFieldLookupListQuery);

    const handleChange = (event: React.ChangeEvent<{}>, value: CustomFieldLookUpListItem[]) => {
        // tslint:disable-next-line: no-console
        console.log('onChange', value);

        if (props.onSelection) {
            props.onSelection(value, props.name ? props.name : 'office');
        }
    };

    const getInputProps = (params: RenderInputParams, isLoading?: boolean) => {
        return  ({   
            ...params.InputProps,
            endAdornment: (
                <React.Fragment>
                  {isLoading ? <CircularProgress color="primary" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),     
         });
    };

    const options = customFieldLookupListQuery.data?.crm.customFieldLookupList.map((item) => {
        return {
            ...item,
            value: item.guidId,
            label: item.description,
        };
    });

    return (
        <div className={`${classes.root} ${props.className}`}>
            { props.multiple ? 
                (
                    <Autocomplete<CustomFieldLookUpListItem>
                        loading={customFieldLookupListQuery.loading}
                        value={props.value}
                        multiple={true}
                        disabled={props.disabled}
                        onChange={handleChange}
                        options={options}
                        onBlur={props.onBlur}
                        disableCloseOnSelect={props.disableCloseOnSelect}
                        disablePortal={props.disablePortal} // to Stop the dropdown select from closing the form
                        // tslint:disable-next-line: jsx-no-lambda
                        getOptionLabel={(option: CustomFieldLookUpListItem) => option.label}     
                        getOptionSelected={(option, value) => value.value === option.value}               
                        // tslint:disable-next-line: jsx-no-lambda
                        renderInput={params => (
                            <TextField 
                                {...params} 
                                label={props.label}
                                variant="standard" 
                                fullWidth={true} 
                                margin="none" 
                                required={props.required}
                                InputProps={getInputProps(params)}
                                error={props.error}      
                                helperText={props.helperText}                      
                            />
                        )}
                        // tslint:disable-next-line: jsx-no-lambda
                        renderOption={(option, { inputValue }) => {
                            const matches = match(option.label, inputValue);
                            const parts = parse(option.label, matches);
                    
                            return (
                                <div>
                                    {parts.map((part, index) => (
                                        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                            {part.text}
                                        </span>
                                    ))}
                                </div>
                            );
                        }}
                    />
                )
                :
                (
                    <Autocomplete
                        value={props.value}
                        disabled={props.disabled}
                        onChange={handleChange}
                        options={options}
                        disableCloseOnSelect={props.disableCloseOnSelect}
                        onBlur={props.onBlur}
                        disablePortal={props.disablePortal} // to Stop the dropdown select from closing the form
                        // tslint:disable-next-line: jsx-no-lambda
                        getOptionLabel={(option: CustomFieldLookUpListItem) => option.label}      
                        getOptionSelected={(option, value) => value.value === option.value}   
                        // tslint:disable-next-line: jsx-no-lambda
                        renderInput={params => (
                            <TextField 
                                {...params} 
                                label={props.label}
                                variant="standard" 
                                fullWidth={true} 
                                margin="none" 
                                required={props.required}
                                InputProps={getInputProps(params)}
                                error={props.error}       
                                helperText={props.helperText}                          
                            />
                        )}
                        // tslint:disable-next-line: jsx-no-lambda
                        renderOption={(option, { inputValue }) => {
                            const matches = match(option.label, inputValue);
                            const parts = parse(option.label, matches);
                    
                            return (
                                <div>
                                {parts.map((part, index) => (
                                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                    {part.text}
                                    </span>
                                ))}
                                </div>
                            );
                        }}
                    />
                )
            }
        </div>
    );
};

interface CustomFieldLookupListData {
    loading?: boolean;
    networkStatus?: number;
    crm: CRM;
};

type CRM = {
    customFieldLookupList: CustomFieldLookupList[];
};

type CustomFieldLookupList = {
    guidId:                         string;
    description:                    string;
    customFieldLookupListItemCount: number;
    sequence:                       number;
};

const CustomFieldLookupListQuery = gql`
    query CustomFieldLookupList {
        crm {
            customFieldLookupList {
                guidId
                description
                customFieldLookupListItemCount
                sequence
            }
        }
    }
`;