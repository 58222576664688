import React from 'react';
import { FieldType } from '../selectors/CustomFieldTypeSelector';
import { Field } from 'react-final-form';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, TextField } from '@material-ui/core';
// import { CurrencyInput2 } from '../../components/CurrencyInput';
import { KeyboardDatePicker } from '@material-ui/pickers/DatePicker/DatePicker';
import { KeyboardDateTimePicker } from '@material-ui/pickers/DateTimePicker/DateTimePicker';
// import { DropzoneArea } from 'material-ui-dropzone';
// import { DocumentFile } from '../../types/DocumentFile';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { Autocomplete } from '@material-ui/lab';
import NumberFormat from 'react-number-format';
import { LightTooltip } from '../../helpers/commonHelper';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { CustomField } from '../CustomFieldRepositores';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

export interface CustomFieldKeyProps {
    customFieldGuid: string;
    customFieldGroupGuid: string;
    customFieldId: string;
    customFieldType: number;
    customFieldValue: any;
    [key: string]: any;
}

// tslint:disable-next-line: no-any
const required = (value: any) => {
    return value ? undefined : 'Required';
};

export const getField = (field: CustomField, index: number, customFieldGroupGuid?: string ) => {

    if (field.customFieldType === FieldType.Label) {
        return (
            <div
                style={{
                    display: 'flex',
                    gap: '5px',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <div style={{ width: '100%' }}>
                    {field.description}
                </div>
                
                {field.instructions && (
                    <LightTooltip
                        arrow={true}
                        title={
                            <div 
                                dangerouslySetInnerHTML={{ __html: field.instructions || ''}}
                            />
                        }
                    >
                        <InfoOutlinedIcon  color="primary" />
                    </LightTooltip>
                )}
            </div>
        );

    } else if (field.customFieldType === FieldType.Integer) { 
        return (
            <Field key={index} name={`customField.${field.guid}`} subscription={{ touched: true, error: true, value: true }} validate={field.isMandatory ? required : undefined}>
                {({ input, meta }) => (
                    <div
                        style={{
                            display: 'flex',
                            gap: '5px',
                            alignItems: 'center',
                            width: '100%'
                        }}
                    >
                        <TextField
                            {...input}
                            style={{
                                width: '100%'
                            }}
                            error={meta.error && meta.touched}
                            label={field.description}
                            helperText={meta.error && meta.touched ? meta.error : field.name}
                            required={field.isMandatory}
                            type="number"
                            value={input.value ? input.value.customFieldValue : ''}
                            onChange={(event) => {
                                const tempValue: CustomFieldKeyProps = {
                                    customFieldGuid: field.guid,
                                    customFieldGroupGuid: customFieldGroupGuid || '',
                                    customFieldId: field.customFieldId,
                                    customFieldType: field.customFieldType,
                                    customFieldValue: event.target.value ? parseInt(event.target.value) : null,
                                }
                                input.onChange(tempValue);
                            }}
                            
                        />
                        {field.instructions && (
                            <LightTooltip
                                arrow={true}
                                title={
                                    <div 
                                        dangerouslySetInnerHTML={{ __html: field.instructions || ''}}
                                    />
                                }
                            >
                                <InfoOutlinedIcon  color="primary" />
                            </LightTooltip>
                        )}
                    </div>
                )}
            </Field>
        );
    }

    if (field.customFieldType === FieldType['Multi Line']) { 
        return (
            <Field key={index} name={`customField.${field.guid}`} subscription={{ touched: true, error: true, value: true }} validate={field.isMandatory ? required : undefined}>
                {({ input, meta }) => (
                    <div
                        style={{
                            display: 'flex',
                            gap: '5px',
                            alignItems: 'center',
                            width: '100%'
                        }}
                    >
                        <TextField
                            {...input}
                            style={{
                                width: '100%'
                            }}
                            error={meta.error && meta.touched}
                            label={field.description}
                            helperText={meta.error && meta.touched ? meta.error : field.name}
                            required={field.isMandatory}
                            multiline={true}
                            minRows={4}
                            value={input.value ? input.value.customFieldValue : ''}
                            onChange={(event) => {
                                const tempValue: CustomFieldKeyProps = {
                                    customFieldGuid: field.guid,
                                    customFieldGroupGuid: customFieldGroupGuid || '',
                                    customFieldId: field.customFieldId,
                                    customFieldType: field.customFieldType,
                                    customFieldValue: event.target.value,
                                }
                                input.onChange(tempValue);
                            }}
                        />
                        {field.instructions && (
                            <LightTooltip
                                arrow={true}
                                title={
                                    <div 
                                        dangerouslySetInnerHTML={{ __html: field.instructions || ''}}
                                    />
                                }
                            >
                                <InfoOutlinedIcon  color="primary" />
                            </LightTooltip>
                        )}
                    </div>
                )}
            </Field>
        );
    }

    if (field.customFieldType === FieldType.Number) { 
        return (
            <Field key={index} name={`customField.${field.guid}`} subscription={{ touched: true, error: true, value: true }} validate={field.isMandatory ? required : undefined}>
                {({ input, meta }) => (
                    <div
                        style={{
                            display: 'flex',
                            gap: '5px',
                            alignItems: 'center',
                            width: '100%'
                        }}
                    >
                        <TextField
                            {...input}
                            style={{
                                width: '100%'
                            }}
                            error={meta.error && meta.touched}
                            label={field.description}
                            helperText={meta.error && meta.touched ? meta.error : field.name}
                            required={field.isMandatory}
                            type="number"
                            value={input.value ? input.value.customFieldValue : ''}
                            onChange={(event) => {
                                const tempValue: CustomFieldKeyProps = {
                                    customFieldGuid: field.guid,
                                    customFieldGroupGuid: customFieldGroupGuid || '',
                                    customFieldId: field.customFieldId,
                                    customFieldType: field.customFieldType,
                                    customFieldValue: event.target.value ? parseFloat(event.target.value) : null,
                                }
                                input.onChange(tempValue);
                            }}
                        />
                        {field.instructions && (
                            <LightTooltip
                                arrow={true}
                                title={
                                    <div 
                                        dangerouslySetInnerHTML={{ __html: field.instructions || ''}}
                                    />
                                }
                            >
                                <InfoOutlinedIcon  color="primary" />
                            </LightTooltip>
                        )}
                    </div>
                )}
            </Field>
        );
    }

    if (field.customFieldType === FieldType.Currency) {
        return (
            <Field key={index} name={`customField.${field.guid}`} subscription={{ touched: true, error: true, value: true }} validate={field.isMandatory ? required : undefined}>
                {({ input, meta }) => (
                    <div
                        style={{
                            display: 'flex',
                            gap: '5px',
                            alignItems: 'center',
                            width: '100%'
                        }}
                    >
                        <TextField
                            {...input}
                            style={{
                                width: '100%'
                            }}
                            error={meta.error && meta.touched}
                            label={field.description}
                            helperText={meta.error && meta.touched ? meta.error : field.name}
                            required={field.isMandatory}
                            InputProps={{
                                inputComponent: NumberFormatCustom as any,
                            }}
                            value={input.value ? input.value.customFieldValue : ''}
                            onChange={(event) => {
                                const tempValue: CustomFieldKeyProps = {
                                    customFieldGuid: field.guid,
                                    customFieldGroupGuid: customFieldGroupGuid || '',
                                    customFieldId: field.customFieldId,
                                    customFieldType: field.customFieldType,
                                    customFieldValue: event.target.value ? parseFloat(event.target.value) : null,
                                }
                                input.onChange(tempValue);
                            }}
                        />
                        {field.instructions && (
                            <LightTooltip
                                arrow={true}
                                title={
                                    <div 
                                        dangerouslySetInnerHTML={{ __html: field.instructions || ''}}
                                    />
                                }
                            >
                                <InfoOutlinedIcon  color="primary" />
                            </LightTooltip>
                        )}
                    </div>
                )}
            </Field>
        );
    }

    if (field.customFieldType === FieldType.Date) {
        return (
            <Field key={index} name={`customField.${field.guid}`} subscription={{ touched: true, error: true, value: true }} validate={field.isMandatory ? required : undefined}>
                {({ input, meta }) => (
                    <div
                        style={{
                            display: 'flex',
                            gap: '5px',
                            alignItems: 'center',
                            width: '100%'
                        }}
                    >
                        <KeyboardDatePicker
                            {...input}
                            style={{
                                width: '100%'
                            }}
                            label={field.description}
                            format="DD/MM/YYYY"
                            placeholder="dd/mm/yyyy"
                            required={field.isMandatory}
                            animateYearScrolling={true}
                            // tslint:disable-next-line: max-line-length
                            autoOk={true}
                            allowKeyboardControl={true}
                            variant={'inline'}
                            error={meta.error && meta.touched}
                            helperText={
                                meta.error && meta.touched
                                    ? meta.error // 'Follow Up Due date is required'
                                    : field.name
                            }
                            inputProps={{ autocomplete: 'off' }}
                            InputLabelProps={{
                                shrink: input.value ? true : false,
                            }}
                            value={input.value ? input.value.customFieldValue : null}
                            onChange={(date) => {
                                const tempValue: CustomFieldKeyProps = {
                                    customFieldGuid: field.guid,
                                    customFieldGroupGuid: customFieldGroupGuid || '',
                                    customFieldId: field.customFieldId,
                                    customFieldType: field.customFieldType,
                                    customFieldValue: date,
                                };
                                input.onChange(tempValue);
                            }}
                        />
                         {field.instructions && (
                            <LightTooltip
                                arrow={true}
                                title={
                                    <div 
                                        dangerouslySetInnerHTML={{ __html: field.instructions || ''}}
                                    />
                                }
                            >
                                <InfoOutlinedIcon  color="primary" />
                            </LightTooltip>
                         )}
                    </div>
                )}
            </Field>
        );
    }

    if (field.customFieldType === FieldType['Date/Time']) {
        return (
            <Field key={index} name={`customField.${field.guid}`} subscription={{ touched: true, error: true, value: true }} validate={field.isMandatory ? required : undefined}>
                {({ input, meta }) => (
                    <div
                        style={{
                            display: 'flex',
                            gap: '5px',
                            alignItems: 'center',
                            width: '100%'
                        }}
                    >
                        <KeyboardDateTimePicker
                            {...input}
                            style={{
                                width: '100%'
                            }}
                            label={field.description}
                            format="DD/MM/YYYY hh:mm a"
                            placeholder="dd/mm/yyyy hh:mm a"
                            required={field.isMandatory}
                            animateYearScrolling={true}
                            // tslint:disable-next-line: max-line-length
                            autoOk={true}
                            allowKeyboardControl={true}
                            variant={'inline'}
                            error={meta.error && meta.touched}
                            helperText={
                                meta.error && meta.touched
                                    ? meta.error // 'Follow Up Due date is required'
                                    : field.name
                            }
                            inputProps={{ autocomplete: 'off' }}
                            InputLabelProps={{
                                shrink: input.value ? true : false,
                            }}
                            value={input.value ? input.value.customFieldValue : null}
                            onChange={(date) => {
                                const tempValue: CustomFieldKeyProps = {
                                    customFieldGuid: field.guid,
                                    customFieldGroupGuid: customFieldGroupGuid || '',
                                    customFieldId: field.customFieldId,
                                    customFieldType: field.customFieldType,
                                    customFieldValue: date,
                                };
                                input.onChange(tempValue);
                            }}
                        />
                        {field.instructions && (
                            <LightTooltip
                                arrow={true}
                                title={
                                    <div 
                                        dangerouslySetInnerHTML={{ __html: field.instructions || ''}}
                                    />
                                }
                            >
                                <InfoOutlinedIcon  color="primary" />
                            </LightTooltip>
                        )}
                    </div>
                )}
            </Field>
        );
    }

    if (field.customFieldType === FieldType['Yes/No']) {
        
        return (
            <Field key={index} name={`customField.${field.guid}`} subscription={{ touched: true, error: true, value: true }} validate={field.isMandatory ? required : undefined}>                                           
                {({ input, meta }) => {
                    
                    const helperText = meta.error && meta.touched ? meta.error : field.name;

                    return (
                        <FormControl 
                            required={field.isMandatory} 
                            component="fieldset" 
                            error={meta.error && meta.touched}
                            style={{ 
                                width: '100%',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    gap: '10px',
                                    alignItems: 'center',
                                    flex: 1
                                }}
                            >
                                <FormLabel 
                                    component="legend"
                                    style={{ flex: 1 }}
                                >
                                    {field.description}
                                </FormLabel>
                                <FormGroup
                                    style={{
                                        flexDirection: 'row'
                                    }}
                                >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                {...input}
                                                checked={input.value.customFieldValue === true}
                                                onChange={(_, checked) => {
                                                    if (input.value.customFieldValue === true) { 
                                                        input.onChange(undefined);
                                                    } else {
                                                        const tempValue: CustomFieldKeyProps = {
                                                            customFieldGuid: field.guid,
                                                            customFieldGroupGuid: customFieldGroupGuid || '',
                                                            customFieldId: field.customFieldId,
                                                            customFieldType: field.customFieldType,
                                                            customFieldValue: true,
                                                        }
                                                        input.onChange(tempValue);
                                                    }
                                                }}
                                                checkedIcon={<CheckBoxIcon color="primary" />}
                                            />
                                        }
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                {...input}
                                                checked={input.value.customFieldValue === false}
                                                onChange={(_, checked) => {
                                                    if (input.value.customFieldValue === false) { 
                                                        input.onChange(undefined);
                                                    } else {
                                                        const tempValue: CustomFieldKeyProps = {
                                                            customFieldGuid: field.guid,
                                                            customFieldGroupGuid: customFieldGroupGuid || '',
                                                            customFieldId: field.customFieldId,
                                                            customFieldType: field.customFieldType,
                                                            customFieldValue: false,
                                                        }
                                                        input.onChange(tempValue);
                                                    }
                                                }}
                                                checkedIcon={<CheckBoxIcon color="primary" />}
                                            />
                                        }
                                        label="No"
                                    />
                                </FormGroup>
                                {field.instructions && (
                                    <LightTooltip
                                        arrow={true}
                                        title={
                                            <div 
                                                dangerouslySetInnerHTML={{ __html: field.instructions || ''}}
                                            />
                                        }
                                    >
                                        <InfoOutlinedIcon  color="primary" />
                                    </LightTooltip>
                                )}
                            </div>
                            {helperText && (
                                <FormHelperText>{helperText}</FormHelperText>
                            )}
                        </FormControl>
                    );
                }}
            </Field>
        );
    }

    // if (field.customFieldType === FieldType['File Upload']) {
    //     return (
    //         <Field key={index} name={`customField.${field.guid}`} subscription={{ touched: true, error: true, value: true }} validate={field.isMandatory ? required : undefined}>                                           
    //             {({ input, meta }) => (
    //                 <div
    //                     style={{
    //                         position: 'relative',
    //                     }}
    //                 >
    //                     <div
    //                         style={{
    //                             position: 'absolute',
    //                             top: '10px',
    //                             right: '10px',
    //                             zIndex: 1
    //                         }}
    //                     >
    //                         <LightTooltip
    //                             arrow={true}
    //                             title={
    //                                 <div 
    //                                     dangerouslySetInnerHTML={{ __html: field.instructions || ''}}
    //                                 />
    //                             }
    //                         >
    //                             <InfoOutlinedIcon  color="primary" />
    //                         </LightTooltip>
    //                     </div>
    //                     <DropzoneArea
    //                         {...input}
    //                         classes={{
    //                             text: 'dropzone-text',
    //                         }}
    //                         // inputProps={{
    //                         //     className: classes.dropZone
    //                         // }}
    //                         key={field.guid}
    //                         maxFileSize={15000000}
    //                         // acceptedFiles={['.msg', '.jpg', '.jpeg', '.png', '.docx', '.zip']}
    //                         showFileNames={true}
    //                         useChipsForPreview={true}
    //                         filesLimit={10}
    //                         dropzoneText={field.description}
    //                         // dropzoneClass={classes.dropZoneFont}
    //                         // tslint:disable-next-line: no-shadowed-variable
    //                         onChange={(files: File[]) => {
    //                             let existingFiles: DocumentFile[] = [...input.value as any];
    //                             const newFiles = files.filter((file: File) => {
    //                                 return !existingFiles.some((item: DocumentFile) => {
    //                                     return item.name === file.name && item.status !== 2;
    //                                 });
    //                             });
    //                             if (newFiles && newFiles.length > 0) {
    //                                 newFiles.forEach((file: File) => {
    //                                     const documentFile: DocumentFile = {
    //                                         guidID: null,
    //                                         file: file,
    //                                         status: 1,
    //                                         name: file.name,
    //                                         url: ''
    //                                     };
    //                                     existingFiles.push(documentFile);
    //                                 });

    //                                 const tempValue: CustomFieldKeyProps = {
    //                                     customFieldGuid: field.guid,
    //                                     customFieldGroupGuid: customFieldGroupGuid || '',
    //                                     customFieldId: field.customFieldId,
    //                                     customFieldType: field.customFieldType,
    //                                     customFieldValue: existingFiles,
    //                                 };

    //                                 input.onChange(tempValue);
    //                             }
    //                         }}
    //                         onDelete={(file: File) => {
    //                             let existingFiles: DocumentFile[] = [...input.value as any];
    //                             // eslint-disable-next-line array-callback-return
    //                             existingFiles.map((item: DocumentFile) => {
    //                                 if (file.name === item.name) {
    //                                     item.file = file;
    //                                     item.status = 2; // deleted
    //                                 }
    //                             });
    //                             // tslint:disable-next-line: max-line-length
    //                             const filteredFiles = existingFiles.filter((item: DocumentFile) => {
    //                                 return item.status !== 2 || item.guidID !== null;
    //                             });
    //                             input.onChange(filteredFiles);
    //                         }}
    //                         // tslint:disable-next-line
    //                         onDrop={(files: File[], event: any) => {
    //                             // let existingFiles: DocumentFile[] = [...input.value];
    //                             // tslint:disable-next-line: max-line-length
    //                             // const existingFile = existingFiles.find((item: DocumentFile) => item.name === file)
    //                             // tslint:disable-next-line:no-console
    //                             console.log(event);
    //                         }}
    //                         onDropRejected={() => {
    //                             // return input.errorNotice('Maximum file upload size is 15MB');
    //                         }}
    //                         initialFiles={input.value ? input.value.customFieldValue.map((file: { url: string; }) => file.url) : []}
    //                     />
    //                 </div>
    //             )}
    //         </Field>
    //     );
    // }

    if (field.customFieldType === FieldType['Lookup List'] && field.customFieldLookupItems) {

        const options: IAutoCompleteItem[] = field.customFieldLookupItems.map((item) => {
            return { 
                ...item,
                value: item.guidId, 
                label: item.description 
            };
        }).sort((a, b) => a.sequence - b.sequence);

        return (
            <Field key={index} name={`customField.${field.guid}`} subscription={{ touched: true, error: true, value: true }} validate={field.isMandatory ? required : undefined}>
                {({ input, meta }) => {
                    return (
                        <div
                            style={{
                                display: 'flex',
                                gap: '5px',
                                alignItems: 'center',
                                width: '100%'
                            }}
                        >
                            <Autocomplete 
                                {...input}
                                style={{
                                    width: '100%'
                                }}
                                value={input.value.customFieldValue}
                                options={options}
                                getOptionLabel={(option) => option.label}
                                onChange={(_, value) => {
                                    const tempValue: CustomFieldKeyProps = {
                                        customFieldGuid: field.guid,
                                        customFieldGroupGuid: customFieldGroupGuid || '',
                                        customFieldId: field.customFieldId,
                                        customFieldType: field.customFieldType,
                                        customFieldValue: value,
                                    }
                                    input.onChange(tempValue);
                                }}
                                // getOptionSelected={(option, value) => option.v === input.value.customFieldValue}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        style={{
                                            width: '100%'
                                        }}
                                        error={meta.error && meta.touched}
                                        label={field.description}
                                        helperText={meta.error && meta.touched ? meta.error : field.name}
                                        required={field.isMandatory}
                                    />
                                )}
                            />
                            {field.instructions && (
                                <LightTooltip
                                    arrow={true}
                                    title={
                                        <div 
                                            dangerouslySetInnerHTML={{ __html: field.instructions || ''}}
                                        />
                                    }
                                >
                                    <InfoOutlinedIcon  color="primary" />
                                </LightTooltip>
                            )}
                        </div>
                    );
                }}
            </Field>
        );
    }

    return (
        <Field key={index} name={`customField.${field.guid}`} subscription={{ touched: true, error: true, value: true }} validate={field.isMandatory ? required : undefined}>
            {({ input, meta }) => (
                <div
                    style={{
                        display: 'flex',
                        gap: '5px',
                        alignItems: 'center',
                        width: '100%'
                    }}
                >
                    <TextField
                        {...input}
                        style={{
                            width: '100%'
                        }}
                        error={meta.error && meta.touched}
                        label={field.description}
                        helperText={meta.error && meta.touched ? meta.error : field.name}
                        required={field.isMandatory}
                        value={input.value ? input.value.customFieldValue : ''}
                        onChange={(event) => {
                            const tempValue: CustomFieldKeyProps = {
                                customFieldGuid: field.guid,
                                customFieldGroupGuid: customFieldGroupGuid || '',
                                customFieldId: field.customFieldId,
                                customFieldType: field.customFieldType,
                                customFieldValue: event.target.value,
                            }
                            input.onChange(tempValue);
                        }}
                    />
                    {field.instructions && (
                        <LightTooltip
                                arrow={true}
                                title={
                                    <div 
                                        dangerouslySetInnerHTML={{ __html: field.instructions || ''}}
                                    />
                                }
                            >
                                <InfoOutlinedIcon  color="primary" />
                            </LightTooltip>
                    )}
                </div>
            )}
        </Field>
    );
    
};

interface NumberFormatCustomProps {
    inputRef: (instance: NumberFormat | null) => void;
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
    prefix?: string;
  }
  
function NumberFormatCustom(props: NumberFormatCustomProps) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
        prefix={props.prefix || '$'}
      />
    );
}